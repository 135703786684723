<template>
	<div class="component c-stories c-stories--list" v-if="data">
		<ul class="reset-list c-stories__list">
			<li
				class="c-stories__item border-b border-style-dashed font-size-16 padding-y-12"
				:class="{ 'border-t bp-1024:border-t-none': index === 0 }"
				v-for="(story, index) in data"
				:key="index"
			>
				<div
					class="c-stories__date text-muted"
					v-luxon:date_full="story.date"
				></div>
				<navigation-link
					_class="display-block text-decoration-none hover:text-decoration-underline"
					:url="story.url_slug"
					>{{ story.title }}</navigation-link
				>
			</li>
		</ul>
		<navigation-link
			url="/about/resources/media-center/news"
			_class="c-stories__archives display-block font-weight-700 hover:text-decoration-underline margin-t-12 text-align-center text-decoration-none"
			>More News</navigation-link
		>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'StoriesList',

	components: {
		NavigationLink,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},

		sectionTitle: {
			type: String,
		},
	},
};
</script>
