<template>
	<main
		class="sa-view-404 padding-t-24
			bp-768:padding-b-24"
	>
		<div class="section-header margin-b-24 bp-768:margin-b-48">
			<h2 class="section-header__title margin-0">
				Page Not Found
			</h2>
		</div>
		<div class="row max-width-grid padding-x-12">
			<div class="col-1-of-1 bp-1024:padding-r-12 bp-1024:col-2-of-3">
				<div
					class="aspect-ratio-16-9 position-relative bg-color-primary"
				>
					<div
						class="font-size-96 font-weight-700 text-on-primary text-transform-uppercase utility-position-center"
					>
						404
					</div>
				</div>
				<div class="border border-t-none padding-24 text-align-center">
					<div class="font-weight-700">
						It looks like you're lost...
					</div>
					<div>The page you are looking for no longer exists.</div>
				</div>
			</div>
			<div class="col-1-of-1 bp-1024:col-1-of-3 no-print">
				<div
					class="margin-t-24 padding-x-12 padding-y-24 bg-color-surface border
					bp-1024:padding-24 bp-1024:margin-t-0 bp-1024:margin-l-12"
				>
					<div
						class="font-weight-700 font-size-18 padding-b-12 border-b text-brand"
					>
						Recent News
					</div>
					<stories
						template="StoriesList"
						section-title="Related News"
						:count="5"
					/>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Stories from '@/components/stories/Stories';

export default {
	name: 'Missing',

	components: {
		Stories,
	},

	created() {
		this.setMetaSidearm('Page Not Found');
	},
};
</script>
